import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import Loader from "./shared/Loader";
import { setIntimationRequest } from '../reducers/intimationRequest.slice';
import { useAppDispatch } from "../app/hooks";
import { dataLayerPush } from "../utils/gtm";

function OOP() {
  const dispatch = useAppDispatch();

    const search = window.location.search;
    const query = new URLSearchParams(search);
    const navigate = useNavigate();
    const intimationID = query.get('intimationID');
    const webComponentRef = useRef(null);

    useEffect(() => {
    initializeOop();
    }, [intimationID]);

    useEffect(()=>{
        webComponentRef.current = document.querySelector("oop-web-component");
        if(webComponentRef?.current){
            webComponentRef?.current?.addEventListener("gtm-events",(e: any)=>{
                dataLayerPush?.(e.detail[0],e.detail[1]);
            })
        }
    },[])

    const initializeOop = () =>{
        const eventQuerry = document.querySelector("oop-web-component")
        eventQuerry?.addEventListener('GO_BACK', (e: any) => {
            navigate(-1);
        })
        eventQuerry?.addEventListener('GO_HOME', (e: any) => {
            navigate("/");
        })
        eventQuerry?.addEventListener("intimation-success", (e: any) => {
            if (e?.detail) {
                dispatch(setIntimationRequest(e.detail))
                navigate("/intimation")
            }
          });
    }

    return (
        <React.Fragment>
            <div style={{padding:'20px'}}>
                {!intimationID ? 
                <Loader/>
                :
                <oop-web-component intimationID={intimationID}></oop-web-component>
            }
            </div>
        </React.Fragment>
    );
}

export default OOP;
