import React, { Suspense, useEffect } from "react";
import "./App.css";
import Login from "./components/Login";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  ScrollRestoration,
  useNavigate,
} from "react-router-dom";
import Home from "./components/Home";
import ClaimSubmission from "./components/ClaimSubmission";
import IntimationComponent from "./components/IntimationComponent";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  beneficiariesAsync,
  getBeneficiaries,
} from "./reducers/beneficiaries.slice";
import { getLogin, getLoginState } from "./reducers/login.slice";
import ClaimListComponent from "./components/ClaimList";
import PolicyListComponent from "./components/PolicyList";
import PolicyDetailsComponent from "./components/PolicyDetails";
import ClaimDetailsComponent from "./components/ClaimDetails";
import MultiplAccountComponent from "./components/MultipleAccount";
import Account from "./components/Account";
import BankAccount from "./components/BankAccount";
import ResetPassword from "./components/ResetPassword";
import SiProtect from "./components/SiProtect";
import FamilyMemberDetails from "./components/FamilyMemberDetails";
import { configAsync, isConfigLoading, getConfig } from "./reducers/config.slice";
import { baseProfileAsync,getBaseProfile, isBaseProfileLoading, baseProfileLoader } from "./reducers/baseProfile.slice";
import { getAccessToken, getMaid } from "./utils";
import { HealthBenefits } from "./components/OpdWallet";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { healthBenefitsAsync } from "./reducers/healthBenefits.slice";
import { activeClaimsAsync } from "./reducers/activeClaims.slice";
import { Help } from "@mediassistrepo/maven-old";

import Hospitals from "./components/Hospitals";
import Logout from "./components/Logout";
import Enrollment from "./components/Enrollment";
import VerifyOtp from "./components/VerifyOtp";
import LinkPolicy from "./components/LinkPolicy";
import ScrollToTop from "./common/ScrollToTop";
import { claimListAsync } from "./reducers/claimList.slice";
import UpdateContactDetails from "./components/UpdateContactDetails";
import { activeIntimationsAsync } from "./reducers/activeIntimations.slice";
import KYC from "./components/Kyc";
import SignUp from "./components/SignUp";
import IntimationDetails from "./components/IntimationDetails";
import { flexDetailsAsync } from "./reducers/flexDetails.slice";
import { enrollmentAsync, getEnrollment } from "./reducers/enrollment.slice";
import { getCookieValue, postToNativeApp } from "./common/helpers";
import DeviceContext from "./utils/device.context";
import SSO from "./components/SSO";
import PrivateRoute from "./routers/PrivateRoute";
import ErrorComponent from "./components/Error";
import { initializeGA } from "./utils/GA_services";
import { getPlatformFromCookie } from "./common/helpers";
import OOP from "./components/OOP";
import Cookies from "js-cookie";
import HelpSection from "./components/Help";
import TermsAndConditions from "./components/TermsAndConditions";
import ClaimDetailsRedirection from "./components/ClaimDetailsRedirection";
import './utils/gtm';
import Auth from "./components/Auth";
import ForgotPassword from "./components/ForgotPassword";
import NotFound from "./components/NotFound";
import NewKYC from "./components/NewKyc";
import Abha from "./components/Abha";
import { dataLayerPush } from "./utils/gtm";
import Constants from "./constants/Constants";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import { SuccessIcon } from "./assets/images/icon";
import NewLinkPolicy from "./components/NewlinkPolicy";
import NotificationsDrawer from "./components/NotificationsDrawer";
import CIS from "./components/CIS";

declare global {
  interface Window {
    dataLayer: any[];
  }
}
function App() {
  const dispatch = useAppDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const beneficiariesResponse = useAppSelector(getBeneficiaries);
  const loginResponse = useAppSelector(getLogin);
  const baseProfile = useAppSelector(getBaseProfile);
  const enrollmentDetails = useAppSelector(getEnrollment);
  const config = useAppSelector(getConfig);
  const isLog = useAppSelector(isConfigLoading);
  const isBPLoading = useAppSelector(baseProfileLoader);
  const [isEcardOpen, setIsEcardOpen] = React.useState(false);
  const [showClaimSubmissionModal, setShowClaimSubmissionModal] =
    React.useState(false);
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const [showConsent, setShowConsent] = React.useState(false)

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(()=>{
    if (beneficiariesResponse){
      if(beneficiariesResponse?.policyDetails?.length > 0 && beneficiariesResponse?.beneficiaryDetails[0]?.polCorporateId !== null 
        && beneficiariesResponse?.beneficiaryDetails[0]?.polCorporateId !== 0 ){
        dataLayerPush(Constants.Instance.CORPORATE_NAME,{
          corporate:beneficiariesResponse?.policyDetails[0]?.polHolderName || "NA"
        })
      }
      else{
        dataLayerPush(Constants.Instance.CORPORATE_NAME,{
          corporate:"NA"
        })
      }
  }
  },[beneficiariesResponse])
  
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(()=>{
    if (beneficiariesResponse){
      if(beneficiariesResponse?.policyDetails?.length > 0 && beneficiariesResponse?.beneficiaryDetails[0]?.polCorporateId !== null 
        && beneficiariesResponse?.beneficiaryDetails[0]?.polCorporateId !== 0 ){
        dataLayerPush(Constants.Instance.CORPORATE_NAME,{
          corporate:beneficiariesResponse?.policyDetails[0]?.polHolderName || "NA"
        })
      }
      else{
        dataLayerPush(Constants.Instance.CORPORATE_NAME,{
          corporate:"NA"
        })
      }
  }
  },[beneficiariesResponse])

  //TODO: If not config async show loader
  useEffect(() => {
    if (getAccessToken()) {
      dispatch(configAsync());
      dispatch(beneficiariesAsync());
      dispatch(baseProfileAsync());
      dispatch(healthBenefitsAsync());
      dispatch(activeClaimsAsync());
      dispatch(activeIntimationsAsync());
    }
  }, [getAccessToken()]);

  useEffect(() => {
    let maid = getMaid();
    if (maid) {
      dispatch(flexDetailsAsync(maid));
    }
  }, [getMaid()]);

  useEffect(() => {
    const userConsent = baseProfile?.data?.profileData?.userConsent;
    if (baseProfile?.data && !userConsent && !isBPLoading) {
      setShowConsent(true);
    }
  }, [baseProfile]);

  useEffect(() => {
    if (getCookieValue("platform") == "ios") {
      window.addEventListener("message", handleMessage);
      return () => {
        window.removeEventListener("message", handleMessage);
      };
    } else if (getCookieValue("platform") == "android") {
      document.addEventListener("message", handleMessage);
      return () => {
        document.removeEventListener("message", handleMessage);
      };
    }
  }, [config]);

  const handleMessage = (event: any) => {
    const message = JSON.parse(event.data);
    if (message.event === "ecardClick") {
      if(!config?.hideEcard){
      postToNativeApp({ hideFooter: true });
      setIsEcardOpen(true);
      }
      else{
        alert("e-card is temporarily restricted by your corporate.")
      }
    } else if (message.event === "submitClaimClick") {
      postToNativeApp({ hideFooter: true });
      setShowClaimSubmissionModal(!showClaimSubmissionModal);
    }
  };

  useEffect(() => {
    initializeGA();
    document.cookie = 'isRedirectToNew=true;max-age=1200;domain=.mediassist.in;path=/';
    if (getCookieValue("source") == "native") {
      window.addEventListener("message", handleMessage);
      return () => {
        window.removeEventListener("message", handleMessage);
      };
    }
  }, []);

  const notify = () => {
   setTimeout(()=>{

    toast(
      <div className="flex gap-4 items-start">
        <div className=""><SuccessIcon /></div>

        <p style ={{fontSize: "0.875rem", fontWeight: 600, color: "black"}}className="text-sm font-semibold text-black">
          KYC verified successfully
        </p>
      </div>
    );
  },1000) 

  };
  return (
    <HelmetProvider>
       <ToastContainer autoClose={4000} hideProgressBar />

      <Helmet>
        <title>Maven</title>
        <script src={process.env.REACT_APP_OOP_URL} async={true} defer={true} />
        <script
          src={process.env.REACT_APP_NETWORK_HOSPITAL_COMPONENT}
          defer={true}
        />
        <script
          src={process.env.REACT_APP_INTIMATION_URL}
          defer={true}
        ></script>
        <script
          src={process.env.REACT_APP_CLAIM_SUBMISSION}
          defer={true}
        ></script>
        <script src={process.env.REACT_APP_KYC_URL} defer={true}></script>
        <script src={process.env.REACT_APP_NEW_KYC_URL} defer={true}></script>
        {/* <script src={process.env.REACT_APP_LINK_POLICY}></script> */}
        <script src={process.env.REACT_APP_NEW_LINK_POLICY }></script>
        
        <script src={process.env.REACT_APP_ELIGIBLITY_URL}></script>

        <script src={process.env.REACT_APP_ABHA_COMPONENT} defer={true}></script>
        <script src={process.env.REACT_APP_NEW_INTIMATION} defer={true}></script>
        <script src={process.env.REACT_APP_OOP_COMPONENT} defer={true}></script>
        <script src={process.env.REACT_APP_CIS}></script>
        <script src={process.env.REACT_APP_FRESHCHAT_URL} id="Freshchat-js-sdk" defer={true}></script>
        {/* <script src="https://stg-component.mediassist.in/out-of-pocket/v3/static/js/main.34344af8.js"></script> */}
      </Helmet>
      <DeviceContext.Provider
        value={{
          isMobile: width <= 768,
          platform: width <= 768 ? getPlatformFromCookie() : "MAVEN|Web",
        }}
      >
          <div>
            <ScrollToTop />
            <div>
              <Routes>
                <Route
                  path="/"
                  element={
                    loginResponse?.accessToken ? 
                        ( showConsent ? <Navigate to="/terms-and-conditions" /> : <Home
                          setIsEcardOpen={setIsEcardOpen}
                          isEcardOpen={isEcardOpen}
                          setShowClaimSubmissionModal={setShowClaimSubmissionModal}
                          showClaimSubmissionModal={showClaimSubmissionModal}
                        /> )
                      :
                      <Login />
                  }
                />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/cis" element={<CIS />} />
                <Route path="/forget-password" element={<ForgotPassword />} />
                <Route path="/claimDetails/:param" element={<ClaimDetailsRedirection/>} />
                <Route path="/auth/:param" element={<Auth/>} />
                <Route path="/help" Component={HelpSection} />
                <Route path="/sso-saml" Component={SSO} />
                <Route path="/reset-password" Component={ResetPassword} />
                 <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route
                  path="/link-policy"
                  element={
                     accessToken ? <Home
                     setIsEcardOpen={setIsEcardOpen}
                     isEcardOpen={isEcardOpen}
                     setShowClaimSubmissionModal={setShowClaimSubmissionModal}
                     showClaimSubmissionModal={showClaimSubmissionModal}
                   /> :  <NewLinkPolicy /> 
                    }
                />
                <Route element={<PrivateRoute consent={showConsent}/>}>
                  <Route
                    path="/claimSubmission/:submissionType"
                    Component={ClaimSubmission}
                  />
                  <Route path="/hospitals" Component={Hospitals} />
                  <Route path="/intimation" element={<IntimationComponent />} />
                  <Route
                    path="/claim-list"
                    element={
                      <ClaimListComponent
                        setIsEcardOpen={setIsEcardOpen}
                        isEcardOpen={isEcardOpen}
                        setShowClaimSubmissionModal={
                          setShowClaimSubmissionModal
                        }
                        showClaimSubmissionModal={showClaimSubmissionModal}
                      />
                    }
                  />
                  <Route
                    path="/policy-list"
                    element={
                      <PolicyListComponent
                        beneficiariesResponse={
                          beneficiariesResponse?.beneficiaryDetails
                        }
                        setIsEcardOpen={setIsEcardOpen}
                        isEcardOpen={isEcardOpen}
                        setShowClaimSubmissionModal={
                          setShowClaimSubmissionModal
                        }
                        showClaimSubmissionModal={showClaimSubmissionModal}
                      />
                    }
                  />
                  <Route path="/policy" Component={PolicyDetailsComponent} />
                  <Route path="/siProtect" Component={SiProtect} />
                  <Route
                    path="/family-member-details"
                    Component={FamilyMemberDetails}
                  />
                  <Route path="/verify-otp/:tag" Component={VerifyOtp} />
                  <Route path="/bankAccount" Component={BankAccount} />
                  <Route
                    path="/claim-details"
                    Component={ClaimDetailsComponent}
                  />
                  <Route
                    path="/multipleAccount"
                    Component={MultiplAccountComponent}
                  />
                  <Route
                    path="/account"
                    element={
                      <Account
                        setIsEcardOpen={setIsEcardOpen}
                        isEcardOpen={isEcardOpen}
                        setShowClaimSubmissionModal={
                          setShowClaimSubmissionModal
                        }
                        showClaimSubmissionModal={showClaimSubmissionModal}
                      />
                    }
                  />
                  <Route path="/enrollment" Component={Enrollment} />
                  <Route path="/health-benefits" Component={HealthBenefits} />
                  <Route
                    path="/updateContact"
                    Component={UpdateContactDetails}
                  />
                  <Route path="/kyc" Component={KYC} />
                  <Route path="/new-kyc" element={<NewKYC notify={notify} />} />

                  <Route
                    path="/intimationDetails/:intimationId"
                    Component={IntimationDetails}
                  />
                  <Route path="/oop" Component={OOP} />
                  <Route path="/abha" Component={Abha} />
                </Route>
                <Route path="/logout" Component={Logout} />
                <Route path="*" Component={NotFound} />
                <Route path="/notifications" Component={NotificationsDrawer} />
              </Routes>
            </div>
          </div>
      </DeviceContext.Provider>
    </HelmetProvider>
  );
}

export default App;
