import React from 'react'
import {
    ForgotPassword
  } from '@mediassistrepo/maven-old';
import { resetPasswordApi } from '../api/loginAPI';
import HeaderWeb from './shared/Header';
import { useNavigate } from 'react-router';
import { ResetPasswordRequest } from '../interfaces/login';

const ForgotPasswordComponent = () => {
  const navigate = useNavigate();

  const handleResetPassword = async (data: ResetPasswordRequest) => {
        const res = await resetPasswordApi(data);
        return res?.data;
  }
    
  return (
    <>
    <HeaderWeb onBackBtnClick={() => navigate('/')}  isShowBackButton={true} oldHeader={true} name={"Go Back"} />
    <ForgotPassword resetPassword={handleResetPassword}/>
    </>
  )
}

export default ForgotPasswordComponent