import React, { useEffect } from 'react'
import {
  HealthBenefitsComponents
} from '@mediassistrepo/maven-old';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getHealthBenefits, healthBenefitsAsync, isHealthBenefitsLoading } from '../reducers/healthBenefits.slice';
import { clearVendorUrl, getvendorUrl, isVendorUrlLoading, vendorUrlAsync } from '../reducers/vendorUrl.slice';
import { configAsync, getConfig } from '../reducers/config.slice';

import Loader from './shared/Loader';
import { clearVendors, getvendors, isVendorLoading, vendorsAsync } from '../reducers/vendors.slice';
import { VendorRequest, VendorUrlRequest, VendorUrlResponse, VendoresResponse } from '../interfaces/cashless.interface';
import { getCookieValue, getCopyData, postToNativeApp } from '../common/helpers';
import { baseProfileAsync, getBaseProfile } from '../reducers/baseProfile.slice';
import { useNavigate } from 'react-router';
import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import Constants from '../constants/Constants';
import RedirectionModal from './RedirectionModal';
import { VendorUrlFormRequest, VendorUrlFormResponse } from '../interfaces/VendorFormUrlForm.interface';
import { getVendorUrlForm, vendorsUrlFormAsync } from '../reducers/vendorFormUrl.slice';
import { vendorUrlFormApi } from '../api/vendorUrlFormApi';
require("@mediassistrepo/maven-old/dist/style.css");
interface SelectedBenf {
  benefAge: number | null;
}

export const HealthBenefits = () => {
  const [vendorId, setVendorId] = React.useState<number>();
  const [benefName, setBenefName] = React.useState<string>("");
  const [benefDetails, setbenefDetails] = React.useState<SelectedBenf>({ benefAge: null });
  const [vendorlistData, setVendorlistData] = React.useState<VendoresResponse>();
  const [vendorUrlResponse, setVendorUrlResponse] = React.useState<VendorUrlResponse>();
  const [isSelectedLocation, setIsSelectedLocation] = React.useState<string>("");
  const [isLoader, setisLoader] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const search = window.location.search;
  const query = new URLSearchParams(search);
  const token = localStorage.getItem("accessToken");
  const selectedBenefitName = query.get('benefitName');
  const dispatch = useAppDispatch();
  const healthbenefitsResponse = useAppSelector(getHealthBenefits)
  const vendorUrlRes = useAppSelector(getvendorUrl);
  const configRes = useAppSelector(getConfig);
  const vendorRes = useAppSelector(getvendors);
  const baseProfile = useAppSelector(getBaseProfile);
  const isHealthBenefLoading = useAppSelector(isHealthBenefitsLoading);
  const isVendorsLoading = useAppSelector(isVendorLoading);
  const isVendorurlLoading = useAppSelector(isVendorUrlLoading);
  const [showPage, setShowPage] = React.useState(Constants.Instance.healthBenefitsState[1]);
  const [selectedBenf, setSelectedBenf] = React.useState<any>({ benefAge: null, benefMemberId: null });
  const [selectedVendor, setSelectedVendor] = React.useState<string>("");
  const [showRedirectionModal, setShowRedirectionModal] = React.useState(false);
  const [redirectUrl, setRedirectUrl] = React.useState(null);
  const [newTab, setNewTab] = React.useState(false);

  useEffect(() => {
    if (vendorUrlRes && vendorUrlRes.isSuccess && !isSelectedLocation) {
      setisLoader(false);
      setVendorUrlResponse({
        ...vendorUrlRes,
        vendorId: vendorId
      });

    }
  }, [vendorUrlRes]);

  useEffect(() => {
    if (vendorRes && vendorRes.isSuccess) {
      if (vendorRes?.isTCS && benefDetails?.benefAge !== null && benefDetails?.benefAge !== undefined && benefDetails?.benefAge <= 40 && !vendorRes?.isHraDone && benefName === 'MP_HEALTHCHECK') {
        const redirectionUrl = 'https://healthrisk.medibuddy.in/?accessToken=' + token;
        if (getCookieValue('source') === 'native') {
          setisLoader(false)
          postToNativeApp({ 'networkHospitalUrl': redirectionUrl })
        } else {
          window.open(redirectionUrl, "_blank");
        }
      }
      setisLoader(false);
      setVendorlistData(vendorRes);
    }
  }, [vendorRes])



  const handleLocationClick = (selectedLocation: string, benefitName: string, key: string) => {
    setisLoader(true)
    if (selectedLocation === Constants.Instance.MEDIBUDDY) {
      const redirectionUrl = 'https://www.medibuddy.in/sl?refurl=consumercorporatelabs&cp=tcs.com&utm_source=ma_portal&AuthToken=' + token;
      if (getCookieValue('source') === 'native') {
        setisLoader(false)
        postToNativeApp({ browserUrl: redirectionUrl })

      } else {
        window.open(redirectionUrl, "_blank");
      }
    }
    else {
      setIsSelectedLocation(selectedLocation);
      const payload: VendorUrlRequest = {
        vendorId: 0,
        benefitName: benefitName,
        benefitNameString: key,
        selectedMemberId: selectedBenf?.benefMemberId
      };
      dispatch(vendorUrlAsync(payload));
    }
  }

  const handleVendorUrlClick = (vendorId: number, benefitName: string, benefitString: string) => {
    if (vendorId === 1061962) {
      const redirectionUrl = 'https://www.medibuddy.in/sl?refurl=consumercorporatelabs&cp=tcs.com&utm_source=ma_portal&AuthToken=' + token;

      if (getCookieValue('source') === 'native') {
        setisLoader(false)
        postToNativeApp({ browserUrl: redirectionUrl })

      } else {
        setShowRedirectionModal(true);
        setRedirectUrl({
          url: redirectionUrl
        });
      }
    } else {
      const payload: VendorUrlRequest = {
        vendorId: vendorId,
        benefitName: benefitName,
        benefitNameString: benefitString,
        selectedMemberId: selectedBenf?.benefMemberId
      };
      // fetchVendorlUrlApi(payload)
      setVendorId(vendorId);
      dispatch(vendorUrlAsync(payload));
    }
  }

  const handleHealthCheckPartnersClick = async (benefitName: string, benefDetails: any) => {
    setisLoader(true);
    const payload: VendorRequest = {
      benefitName: benefitName,
    };
    setBenefName(benefitName);
    setbenefDetails(benefDetails);
    dispatch(vendorsAsync(payload));
  }

  useEffect(() => {
    setVendorlistData(null)
    setVendorUrlResponse(null)
    dispatch(clearVendorUrl())
    dispatch(clearVendors())
  },[selectedBenefitName])

  const handleClickOnAgree = (VendorUrlRes: any) => {
        setNewTab(false);
    if (VendorUrlRes.vendorId == 1188850) {
      setRedirectUrl({
        vendorId: VendorUrlRes.vendorId,
        url: "https://www.healthi.in/api/v1/users/login_mediassit",
        formData: {
          MAToken: token,
          EmployeeID: baseProfile?.data?.employeeId || ""
        }
      });
      setNewTab(true);
      setShowRedirectionModal(true);
    } else if (VendorUrlRes.vendorId == 1061962) {
      const redirectionUrl = `https://www.medibuddy.in/sl?cp=AMZ&utm_source=ma_portal&AuthToken=${token}&MBEntityId=`;
      if (getCookieValue('source') === 'native') {
        setisLoader(false)
        postToNativeApp({ browserUrl: redirectionUrl })
      } else {
        setRedirectUrl({
          vendorId: VendorUrlRes.vendorId,
          url: redirectionUrl
        });
        setShowRedirectionModal(true);
      }
    } else if (VendorUrlRes.vendorId == 1204529) {
      setRedirectUrl({
        vendorId: VendorUrlRes.vendorId,
        url: VendorUrlRes.vendorAPIURL,
        formData: VendorUrlRes.formData
      });
      setNewTab(true);
      setShowRedirectionModal(true);
    }
    else { 
      if (getCookieValue('source') === 'native') {
        setisLoader(false)
        postToNativeApp({ browserUrl: VendorUrlRes?.redirectionURL })

      } else {
        setNewTab(true);
        setRedirectUrl({
          url: VendorUrlRes.redirectionURL
        });
        setShowRedirectionModal(true);
      }
    }
  }

  const handleBackLogic = () => {
    if (showPage == Constants.Instance.healthBenefitsState[1]) {
      setVendorlistData(null)
      setVendorUrlResponse(null)
      dispatch(clearVendorUrl())
      dispatch(clearVendors())
      navigate(-1)
    }
    else if (showPage == Constants.Instance.healthBenefitsState[2]) {
      setShowPage(Constants.Instance.healthBenefitsState[1])
    }
    else if (showPage == Constants.Instance.healthBenefitsState[3]) {
      setShowPage(Constants.Instance.healthBenefitsState[2])
    }
    else if (showPage == Constants.Instance.healthBenefitsState[4]) {
      setShowPage(Constants.Instance.healthBenefitsState[1]);
    }
  }

  const handleConfirmClick = async() => {
    if (newTab && redirectUrl) {
      if (redirectUrl?.vendorId === 1188850) {
        var t = `<form action="${redirectUrl.url}" method="post">
                   <input type="hidden" name="MAToken" value="${redirectUrl.formData?.MAToken}" />
                   <input type="hidden" name="EmployeeID" value="${redirectUrl.formData?.EmployeeID}" />
                 </form>
                 <script type="text/javascript">
                   function abc() { document.getElementsByTagName("form")[0].submit(); }
                   abc();
                 </script>`;
        var myWindow = window.open("", "_blank");
        myWindow?.document.write(t);
        myWindow?.document.write("</body>");
        myWindow?.document.write("</body>");

      } else if (redirectUrl?.vendorId === 1204529) {
        const payload: VendorUrlFormRequest = {
          url: redirectUrl.url,
          formData: {
            "message": redirectUrl.formData?.message,
            "auth_tag": redirectUrl.formData?.auth_tag
          }
        }
        let res = await dispatch(vendorsUrlFormAsync(payload));
        if (getCookieValue('source') === 'native') {
          postToNativeApp({ "networkHospitalUrl": res?.payload?.url })
        } else {
          window.open(res?.payload?.url, '_blank', 'noopener,noreferrer');
        }
      } else {
        window.open(redirectUrl.url,"_blank");
      }
    } else if (redirectUrl) {
      window.open(redirectUrl.url, "_blank")
    }
    setShowRedirectionModal(false);
    setNewTab(false);
  };
  
  return (
    <>
      <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Health Benefits"} onBackBtnClick={() => { handleBackLogic() }} />
      {(isHealthBenefLoading || isVendorsLoading || isVendorurlLoading || isLoader) && <Loader />}
      <HealthBenefitsComponents
        healthBenefits={healthbenefitsResponse?.isSuccess ? healthbenefitsResponse : {}}
        policyConfigDetails={configRes?.isSuccess ? configRes : {}}
        selectedBenefitName={selectedBenefitName}
        VendorUrlRes={vendorUrlResponse ? vendorUrlResponse : {}}
        healthCheckupResponse={vendorlistData}
        handleHealthCheckPartnersClick={handleHealthCheckPartnersClick}
        handleVendorUrlClick={handleVendorUrlClick}
        handleLocationClick={handleLocationClick}
        handleClickOnAgree={handleClickOnAgree}
        showPage={showPage}
        setShowPage={setShowPage}
        setSelectedBenf={setSelectedBenf}
        selectedBenf={selectedBenf}
        setSelectedVendor={setSelectedVendor}
        selectedVendor={selectedVendor}
      />
       <RedirectionModal
       isVisible={showRedirectionModal}
       toggleBottomModal={()=>{ setShowRedirectionModal(false)}}
       handleCancelClick={()=>{ setShowRedirectionModal(false)}}
       handleConfirmClick={handleConfirmClick}
       />
    </>
  )
}