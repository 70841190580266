import React, { useEffect, useState,useRef } from 'react';
import { ScrollRestoration, useNavigate } from 'react-router-dom';
import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import { useParams } from 'react-router';
import { useAppSelector } from '../app/hooks';
import { getConfig } from '../reducers/config.slice';
import Loader from './shared/Loader';
import SurveyMain from '../common/Survey/SurveyMain';
import Constants from '../constants/Constants';
import { dataLayerPush } from '../utils/gtm';
import { getBeneficiaries } from '../reducers/beneficiaries.slice';

export default function ClaimSubmission(props: any) {
    const [loading, setLoading] = useState(true)
    const history = useNavigate();
    const params = useParams()
    const config = useAppSelector(getConfig);
    const submissionType = params.submissionType;
    const search = window.location.search;
    const query = new URLSearchParams(search);
    const [buttonClick, setButtonClick] = React.useState(null);
    const [showFeedBack,setShowFeedBack] = React.useState<boolean>(false);
    const [clmRefNo,setClmRefNo]= React.useState<string>(''); 
    const webComponentRef = useRef(null);
    const beneficiaryDetails = useAppSelector(getBeneficiaries);
    const pribenef = beneficiaryDetails?.beneficiaryDetails.filter((x:any)=>x?.relationToPrimaryId === 1);
    useEffect(() => {
        let node = document.getElementById("claim-submission");
        if (node) {
            node.appendChild(document.createElement("claim-submission"));
            const component: any = document.querySelector("claim-submission");
            if (component) {
                component.accessToken = localStorage.getItem('accessToken');
                component.claimType = submissionType;
                component.benefitsType = query.get('benefitsType');
                component.homePage = query.get('homePage');
                component.xrefdata = localStorage.getItem('X-REF-DATA');
                component.event = buttonClick
                component.dbType = localStorage.getItem('dbType');
                component.entityId = props?.location?.state?.entityID
                component.entityType = "BENEFID"
                component.isGeneric = config?.isGenericClaimSubmission ? "true" : null;
                component.selectedCountry = JSON.stringify(props?.location?.state?.country)
                component.APP_API_URL = `${process.env.REACT_APP_BASE_URL}`;
                component.APP_GA_ID = `${process.env.REACT_APP_GA_ID}`;
                component.priBenef = JSON.stringify(pribenef);
            }
        }
        setLoading(false)
    }, [buttonClick, config, pribenef]);

    useEffect(() => {
        webComponentRef.current = document.querySelector("claim-submission");
        if(webComponentRef.current){
            webComponentRef.current.addEventListener('selectMember', (e: any) => {
                dataLayerPush(Constants.Instance.SELECT_INSURED_MEMBER, { 
                    claim_type: e?.detail == "IPD" ? "Hospitalisation" : "Clinic / OPD"
                  });
            })
            webComponentRef.current.addEventListener('ipdSubmissionCta',(e:any)=>{
                dataLayerPush(Constants.Instance.SUBMISSIONS_IPD_CTA_INTERACTION, { 
                    cta_text:e.detail[0],
                    section:e.detail[1]
                  });
            })
        }
    },[])

    useEffect(() => {
        const component: any = document.querySelector("claim-submission");
        if(component){
            component.addEventListener('back', (e: any) => {
                history(-1)
            })
            component.addEventListener('success', (e: any) => {
                setClmRefNo(e?.detail?.ClaimReferenceNo)
                setShowFeedBack(true);
            })
        }
    },[])

    return (
        <>
            {loading && <Loader />}
           <HeaderWeb
                isShowBackButton={true}
                oldHeader={true}
                name={"Submit Claim"}
                onBackBtnClick={() => { setButtonClick(!buttonClick) }}
            />
            <div style={{ marginBottom: "100px", paddingTop: "75px" }}>
                <span id="claim-submission">
                </span>
                {showFeedBack &&<div className="relative">
                    <SurveyMain pageType="Claim Submission" pageTitle='The claim has been submitted successfully.' Id={clmRefNo} source={"clmsubmit"} entity_Type={"CLM_SUB"} closeFeedbackModal={() => setShowFeedBack(!showFeedBack)} />
                </div>}
            </div>
        </>
    )
}