import { Help } from "@mediassistrepo/maven-old";
import { useAppSelector } from "../app/hooks";
import { getConfig, isConfigLoading } from "../reducers/config.slice";
import Loader from "./shared/Loader";
import { dataLayerPush } from '../utils/gtm';
import { getBaseProfile } from "../reducers/baseProfile.slice";
import { useRef, useEffect, useState } from "react";
import { BaseProfileResponse } from "../interfaces/baseProfile.interface";
import { BeneficiariesResponse } from "../interfaces/beneficiaries.interface";
import { getBeneficiaries } from "../reducers/beneficiaries.slice";
import { FreshChat, FreshchatInitProps, FreshchatUserProps, UserPropsEnum, FreshchatConversationProps, ConversationPropsEnum } from "../interfaces/config.interface";

declare global {
  interface Window {
    fcWidget:any;
  }
}

const HelpSection = () => {
    const corporateConfig = useAppSelector(getConfig);
    const configLoading = useAppSelector(isConfigLoading);
    const baseProfile = useAppSelector(getBaseProfile);
    const benefResponse = useAppSelector(getBeneficiaries);
    const fcWidgetRef = useRef(null);
    const [isWidgetLoaded,setIsWidgetLoaded] = useState(false);

    useEffect(() => {
        if(corporateConfig?.freshchat && corporateConfig?.freshchat?.showFreshChatButton &&  baseProfile?.data && benefResponse){
          const initializationProps = getInitProps(corporateConfig.freshchat);
          const userProps = getUserProps(corporateConfig.freshchat,baseProfile);
          const convProps = getConversationProps(corporateConfig.freshchat,benefResponse);
          if(fcWidgetRef && fcWidgetRef.current){
            if(!fcWidgetRef?.current?.isInitialized()){
              fcWidgetRef.current?.init({...initializationProps, externalId: baseProfile?.data?.maid });
            }
           fcWidgetRef.current?.user?.clear();
           fcWidgetRef.current?.user?.setProperties(userProps);
           fcWidgetRef.current?.conversation?.setProperties(convProps);
          }else{
           fcWidgetRef.current  = window.fcWidget;
           fcWidgetRef.current?.init({...initializationProps, externalId: baseProfile?.data?.maid });
           fcWidgetRef.current?.user?.clear();
           fcWidgetRef.current?.user?.setProperties(userProps);
           fcWidgetRef.current?.conversation?.setProperties(convProps);
           fcWidgetRef.current?.on("widget:loaded", function(resp:any) {
             setIsWidgetLoaded(true);
           });
      }
    }
       

    },[corporateConfig,baseProfile,benefResponse]);

    useEffect(() => {
     return () => {
      fcWidgetRef?.current?.destroy();
     }
    },[]);

    const openFreshChatBot = () => {
      fcWidgetRef?.current?.open();
    }

    const getInitProps = (freshchatConfig: FreshChat) => {
          const initProps = new FreshchatInitProps();
          initProps.host = `${process.env.REACT_APP_FRESHCHAT_HOST}`;
          initProps.token = `${process.env.REACT_APP_FRESHCHAT_TOKEN}`;
          if(freshchatConfig?.widgetUuid){
            initProps.widgetUuid = freshchatConfig.widgetUuid;
          }
          if(freshchatConfig?.tags){
            initProps.tags = freshchatConfig.tags;
          }
          return initProps;
    }

    const getUserProps = (freshchatConfig: FreshChat,baseProfile: BaseProfileResponse) => {
      const userProps = new FreshchatUserProps();
      if(freshchatConfig?.userProperties?.length && baseProfile?.data){
        freshchatConfig.userProperties.forEach((userProp) => {
            if(userProp == UserPropsEnum.EMAIL){
              userProps.email = baseProfile?.data?.emailId;
            }else if(userProp == UserPropsEnum.FIRST_NAME){
                userProps.firstName = baseProfile?.data?.firstName;
            }else if(userProp == UserPropsEnum.CF_GROUPFORTEST){
              userProps.cf_groupfortest = freshchatConfig?.groupId;
            }
        });
      }
      return userProps;
    }

    const getConversationProps = (freshchatConfig: FreshChat, benefRespone : BeneficiariesResponse) => {
      const conversationProps = new FreshchatConversationProps();
      if(freshchatConfig?.conversationProperties?.length){
        freshchatConfig.conversationProperties.forEach((conversationProp) => {
            if(conversationProp == ConversationPropsEnum.CORPORATE){
              conversationProps.corporate = (benefRespone?.policyDetails?.length >0 ) ?  benefRespone?.policyDetails[0].polHolderName: '';
            }else if(conversationProp == ConversationPropsEnum.FOLDER){
                conversationProps.folder = freshchatConfig?.folder;
            }
        });
      }
      return conversationProps;
    }
    return (
      <>
      {configLoading && <Loader />}
      {!configLoading && <Help configData={corporateConfig} showFreshChatButton={isWidgetLoaded && corporateConfig?.freshchat?.showFreshChatButton} freshChatButtonText={corporateConfig?.freshchat?.freshChatButtonText}  openFreshChatBot={openFreshChatBot} dataLayerPush={dataLayerPush}/>}
      </>

    )
}

export default HelpSection