// @ts-ignore
import {
    PolicyDetails
} from '@mediassistrepo/policy-details';
import "@mediassistrepo/policy-details/dist/style.css";
import { beneficiariesAsync, getBeneficiaries, getBeneficiariesLoading, getBeneficiariesStatus } from '../reducers/beneficiaries.slice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FooterWeb from './shared/Footer';
import { getConfig } from '../reducers/config.slice';
import { getPolicyDocuments, policyAsync } from '../reducers/policy.slice';
import { getCookieValue, logAPIStatus, postToNativeApp } from '../common/helpers';
import { getLogAPIStatusDTO } from '../common/loggerDtoGenerator';
import { RouteConstant, LoggerConstant } from "../common/common.constants";
import Loader from './shared/Loader';
import { MemberdomiBalApi } from '../api/policyAPI';
import { formatDateToObject } from '../utils';
import HeaderWeb from './shared/Header';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';

export default function PolicyDetailsComponent(props: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const beneficiaries = useAppSelector(getBeneficiaries)
    const benefStatus = useAppSelector(getBeneficiariesStatus);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const policyDocumentsResponse = useAppSelector(getPolicyDocuments)
    const polid = searchParams.get("policyId");
    const corporateConfig = useAppSelector(getConfig);
    const [loading, setLoading] = useState(true)
    const nativeApp = getCookieValue('source') === 'native' ? true : false;
    const [bsiDetails, setBsiDetails] = useState([]);

    const getDomiBSI = async () => {
        const benefDetails = selectedPolicy.benefDetail.map((benef:any) => {
                return {
                    benefMediAssistID: benef.maid,
                    benefUserID: benef.userId,
                    dbType: benef.sourceDBType,
                }
        });
        const payload = {
            benefDetails: benefDetails
        }
        const response = await MemberdomiBalApi(payload)
        if (response && response.data && response.data.domiBSIDetails) {
            setBsiDetails(response.data.domiBSIDetails)
        }
    }

    useEffect(() => {
        if (!beneficiaries)
            dispatch(beneficiariesAsync());
        else {
            setSelectedPolicy(beneficiaries.policyDetails.filter(x => x.policyId.toString() === polid)[0])
        }
    }, [beneficiaries])

    useEffect(() => {
        if(selectedPolicy && selectedPolicy.benefDetail){
            getDomiBSI();
        }
    },[selectedPolicy])

    useEffect(() => {
        dispatch(policyAsync())
        setLoading(false)
    }, []);
    useEffect(() => {
        if (beneficiaries)
            logAPIStatus(getLogAPIStatusDTO(LoggerConstant.POLICY, 'claim/beneficiaries', {}, beneficiaries?.policyDetails, LoggerConstant.POLICY));
    }, [beneficiaries])
    return (
        <>
            {loading && <Loader />}
            <HeaderWeb
                show={false}
            />
            {selectedPolicy && policyDocumentsResponse &&  <div className='relative' style={{ padding: "20px 20px 100px 20px", zIndex: 1 }}>
                <div className="pt-6">
                    <PolicyDetails
                        domiBalanceDetails={bsiDetails}
                        policyConfigConditionData={corporateConfig}
                        policyDetails={selectedPolicy}
                        policyDocuments={policyDocumentsResponse}
                        dataLayerPush={dataLayerPush}
                        handleDownload={(docPath: any) => {
                            dataLayerPush(Constants.Instance.VIEW_POLICY_DOCUMENTS_CTA_CLICK, { 
                                cta_text:"Download"
                              });
                            if (nativeApp) {
                                if (!docPath.includes("https:")) {
                                    postToNativeApp({ "browserUrl": `${process.env.REACT_APP_MEDIASSIST_PORTAL}${docPath}` })
                                }
                                else {
                                    postToNativeApp({ "browserUrl": docPath })
                                }
                            } else {
                                if (!docPath.includes("https:")) {
                                    let url = new URL(`${process.env.REACT_APP_MEDIASSIST_PORTAL}${docPath}`);
                                    window.open(url)
                                }
                                else {
                                    window.open(docPath)
                                }
                            }
                        }}
                        handlePolicyBackClick={() => { navigate(-1) }}
                    />
                </div>
            </div>}
            {/* <FooterWeb /> */}
        </>
    )
}