import React from "react";
import {Banner} from '@mediassistrepo/homepage-component';
import { BannerDetail } from "../../interfaces/config.interface";
import { dataLayerPush } from "../../utils/gtm";

interface BannerSectionProps {
    bannerDetails: Array<BannerDetail>;
}

const BannerSection = ({bannerDetails}: BannerSectionProps) => {
  return (
    <>
      {bannerDetails?.length > 0 && (
        <div style={{ paddingTop: 15 }}>
          <Banner bannerDetails={bannerDetails} dataLayerPush={dataLayerPush}/>
        </div>
      )}
    </>
  );
};

export default BannerSection;
